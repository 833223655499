import {fetchScript} from "./utils";

export const initPickupPointsMap = (modalId, modalUniqueId) => {
  if (modalId === "deliveryPage") {
    if (MpState.yandexDeliveryInit) {
      return;
    }

    MpState.yandexDeliveryInit = true;
  }

  // Враппер
  const wrapperElement = document.querySelector(`#mypet-modal-wrapper--${modalId}`);
  const mapElement = wrapperElement.querySelector(".mypet-modal-pickup-points__map-wrapper");
  mapElement.setAttribute("id", `${modalId}--map`);

  // Подключаем Яндекс.Карты
  fetchScript(`https://api-maps.yandex.ru/2.1?load=package.standard&lang=ru_RU&apikey=${MpState.settings.yandex_maps_api_key}`)
    .then(() => {
      ymaps.ready(() => {
        if (typeof MpState.custom.yandexMaps == "undefined") {
          MpState.custom.yandexMaps = {};
        }

        let yandexMap = new ymaps.Map(`${modalId}--map`, {
          zoom: 9,
          center: [parseFloat(mapElement.getAttribute("data-map-center-lat")), parseFloat(mapElement.getAttribute("data-map-center-lon"))],
          controls: ['zoomControl', 'searchControl']
        });

        let yandexCluster = new ymaps.Clusterer({
          preset: 'islands#invertedOrangeClusterIcons',
          groupByCoordinates: false,
          clusterHideIconOnBalloonOpen: false,
          geoObjectHideIconOnBalloonOpen: false,
        });

        // Получаем тег скрипт
        const scriptElement = wrapperElement.querySelector("script");
        // Инджектим его код в браузер
        const yandexScriptElement = document.createElement("script");
        try {
          yandexScriptElement.appendChild(document.createTextNode(scriptElement.innerText));
        } catch(e) {
          yandexScriptElement.text = scriptElement.innerText;
        }
        document.querySelector("head").appendChild(yandexScriptElement);

        // Теперь получаем пункты выдачи
        const pickupPoints =  MpState.custom[`mypetYandexMapsPickupPoints${modalUniqueId}`];
        if (pickupPoints && Array.isArray(pickupPoints)) {
          const geoObjects = [];

          pickupPoints.forEach((pickupPoint, pickupPointIndex) => {
            geoObjects[pickupPointIndex] = new ymaps.Placemark(pickupPoint.coord, getPickupPointData(pickupPoint), getPickupPointMapOptions(pickupPoint));
          });

          yandexCluster.options.set({
            gridSize: 80,
          });

          // Добавляем пункты выдачи на карту
          yandexCluster.add(geoObjects);
          yandexMap.geoObjects.add(yandexCluster);

          // Позиционируем карту по добавленным точкам
          yandexMap.setBounds(yandexCluster.getBounds(), {
            checkZoomRange: true
          });
        }

        MpState.custom.yandexMaps[modalUniqueId] = yandexMap;
      });
    });
};

const getPickupPointMapOptions = (pickupPoint) => {
  let options = {
    preset: 'islands#icon',
    iconColor: '#414141',
  };
  let customIconUsed = false;

  switch (pickupPoint.company) {
    case 'sdek':
      options.iconImageHref = '/dist_new/svg/icons/map/maps-placemark__cdek.svg';
      customIconUsed = true;
      break;
    case 'pickpoint':
      options.iconImageHref = '/dist_new/svg/icons/map/maps-placemark__pick-point.svg';
      customIconUsed = true;
      break;
    case 'boxberry':
      options.iconImageHref = '/dist_new/svg/icons/map/maps-placemark__boxberry.svg';
      customIconUsed = true;
      break;
    case 'pec':
    case 'pek':
      options.iconImageHref = '/dist_new/svg/icons/map/maps-placemark__pec.svg';
      customIconUsed = true;
      break;
  }

  if (customIconUsed) {
    options.iconLayout = 'default#image';
    options.iconImageSize = [52, 64];
    options.iconImageOffset = [-26, -52];
  }

  return options;
};

const getPickupPointData = (pickupPoint) => {
  let scheduleString = '<strong>' + pickupPoint.name + '</strong><br><strong>Адрес:</strong> ' + pickupPoint.address;
  if (pickupPoint.schedule) {
    scheduleString += '<br><strong>Время работы:</strong> ' + pickupPoint.schedule;
  }
  return {
    balloonContentBody: scheduleString,
    clusterCaption: pickupPoint.company_name
  };
};
