import request from "then-request";
import {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} from "body-scroll-lock";
import {elementByQuery} from "./utils";

let confirmInit = false;

/**
 * @param {string} options.id Id модального окна
 * @param {boolean} options.force Если true, то модальное окно принудительно подгрузится второй раз
 * @param {boolean} options.ajax Является ли мобальное окно подгружаемое AJAX
 * @param {string} options.url Адрес для подгрузки
 * @param {boolean} options.mobileFixed Если true, то на маленьких экранах мобальное окно будет на 100% по высоте и ширине
 * @param {string} options.class Класс, который будет добавлен к модальному окну
 * @param {boolean} options.closeOnBgClick Закрывать ли модальное окно при клике по фону
 * @param {function} options.onInit Вызывается после первой инициации модального окна
 * @param {boolean} options.isConfirm
 *
 * @return {Promise}
 */
export const showModal = (options = {}) => {
  const sideBar = elementByQuery("#mypet-sidebar__wrapper");

  return new Promise((resolve, reject) => {
    options = Object.assign({
      ajax: false,
      force: false,
      mobileFixed: false,
      closeOnBgClick: true,
      isConfirm: false,
    }, options);

    // Если это мобильное приложение и показан сайдбар - прекращаем его лок скролла
    if (sideBar) {
      const mainContainer = elementByQuery("#mypet-main-container");
      if (mainContainer.classList.contains("mypet-sidebar--active")) {
        clearAllBodyScrollLocks();
      }
    }

    // Пробуем получить это модальное окно
    const modalWrapperElementId = `mypet-modal-wrapper--${options.id}`;
    let modalWrapper = document.getElementById(modalWrapperElementId);
    if (modalWrapper) {
      disableBodyScroll(modalWrapper);

      if (!options.force) {
        modalWrapper.classList.add("mypet-modal-wrapper--visible");
        if (options.isConfirm && !confirmInit) {
          setupModal(modalWrapper, options);

          modalWrapper.querySelector("button.mypet-confirm__confirm-button").addEventListener("click", () => {
            enableBodyScroll(modalWrapper);
            if (typeof MpState.confirmHandler === "function") {
              MpState.confirmHandler();
            }
          });

          confirmInit = true;
        }
        return resolve();
      } else {
        modalWrapper.remove();
      }
    }

    if (options.ajax) {
      let loaderElement = document.querySelector("#mypet-loader__modal-loader");
      if (loaderElement) {
        loaderElement.classList.add("mypet-loader__wrapper--visible");
      }

      request('GET', options.url).done((response) => {
        if (loaderElement) {
          loaderElement.classList.remove("mypet-loader__wrapper--visible");
        }

        if (response.statusCode >= 200 && response.statusCode < 300) {
          let modalWrapper = document.createElement("div");
          modalWrapper.className = `mypet-modal__wrapper mypet-modal-wrapper--visible`;
          if (options.mobileFixed) {
            modalWrapper.classList.add("mypet-modal__wrapper--mobile-fixed");
          }
          modalWrapper.id = modalWrapperElementId;
          modalWrapper.innerHTML = response.getBody("utf-8");
          setupModal(modalWrapper, options);

          document.querySelector("#mypet-modals-container").appendChild(modalWrapper);

          disableBodyScroll(modalWrapper);

          if (typeof options.onInit === "function") {
            options.onInit();
          }

          resolve();
        } else {
          reject();
        }
      });
    }
  });
};

const setupModal = (modalWrapper, options) => {
  modalWrapper.querySelectorAll(".mypet-modal__close-action").forEach((element) => {
    element.addEventListener("click", (event) => {
      event.preventDefault();
      modalWrapper.classList.remove("mypet-modal-wrapper--visible");
      enableBodyScroll(modalWrapper);
    });
  });
  if (options.closeOnBgClick) {
    modalWrapper.addEventListener("click", (event) => {
      if (event.target && event.target.classList.contains("mypet-modal__wrapper")) {
        modalWrapper.classList.remove("mypet-modal-wrapper--visible");
        enableBodyScroll(modalWrapper);
      }
    });
  }
};
