import debounce from "lodash/debounce";
import {Xhttp, abortSilently} from "xhttp";
import "./search.css";

/**
 * @param {Element} inputElement
 */
export const initSearchAutocomplete = (inputElement) => {
  inputElement.setAttribute("autocomplete", "off");
  const suggestionsElement = document.createElement("div");
  suggestionsElement.classList.add("mypet-autocomplete__suggestions");
  const suggestionsContainer = inputElement.parentElement;

  let suggestions = {};
  let suggestionIndex = -1;
  let suggestionsCount = 0;
  let inputQuery = "";
  let mousedownHappened = false;

  let autocompleteRequest;

  suggestionsContainer.appendChild(suggestionsElement);

  /**
   * Для предотвращения потери фокуса с поля ввода при клике на подсказе
   */
  suggestionsElement.addEventListener("mousedown", () => {
    mousedownHappened = true;
  });

  inputElement.addEventListener("blur", (event) => {
    if (mousedownHappened) {
      event.preventDefault();
      mousedownHappened = false;

      return;
    }

    suggestionsElement.style.display = "none";
  });

  inputElement.addEventListener("focus", () => {
    if (suggestionsCount > 0) {
      suggestionsElement.style.display = "block";
    }
  });

  /**
   * Обработчик ввода запроса
   */
  inputElement.addEventListener("keyup", debounce(async (event) => {
    inputQuery = event.target.value.toString().trim();

    // Скрываем подсказки если поле пустое
    if (inputQuery.length === 0) {
      suggestionsElement.style.display = "none";
      return;
    }

    // Отменяем прошлый запрос
    if (autocompleteRequest) {
      abortSilently(autocompleteRequest);
    }

    try {
      autocompleteRequest = Xhttp({
        url: `/search/suggestions?query=${encodeURIComponent(inputQuery)}`,
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        }
      },({ok, status, reason, headers, body}) => {
        if (ok) {
          suggestions = body.suggestions;
          suggestionsCount = suggestionsLength(suggestions);
          suggestionIndex = -1;
          suggestionsElement.style.display = "block";
          let suggestionsHtml = `<a href="/search?query=${encodeURIComponent(inputQuery)}" class="mypet-search__suggestion"><span>Искать <strong>«${inputQuery}»</strong>...</span></a>`;
          if (suggestionsCount > 0) {
            if (suggestions.products && suggestions.products.length > 0) {
              suggestionsHtml += `<div class="mypet-search__suggestion-title">${suggestions.products.length > 1 ? "Товары" : "Найденный товар"}</div>`;
              suggestions.products.forEach((suggestion) => {
                suggestionsHtml += buildSuggestionHtml(suggestion);
              })
            }
            if (suggestions.categories && suggestions.categories.length > 0) {
              suggestionsHtml += '<div class="mypet-search__suggestion-title">Категории</div>';
              suggestions.categories.forEach((suggestion) => {
                suggestionsHtml += buildSuggestionHtml(suggestion);
              })
            }
            if (suggestions.brands && suggestions.brands.length > 0) {
              suggestionsHtml += '<div class="mypet-search__suggestion-title">Бренды</div>';
              suggestions.brands.forEach((suggestion) => {
                suggestionsHtml += buildSuggestionHtml(suggestion);
              })
            }
            if (suggestions.suggestions && suggestions.suggestions.length > 0) {
              suggestionsHtml += '<div class="mypet-search__suggestion-title">Подсказки</div>';
              suggestions.suggestions.forEach((suggestion) => {
                suggestionsHtml += buildSuggestionHtml(suggestion);
              })
            }
          }
          suggestionsElement.innerHTML = suggestionsHtml;
        }
      });
    } catch (error) {
      //
    }
  }, 100));
};

const suggestionsLength = (suggestions) => {
  let count = 0;
  if (suggestions.categories) {
    count += suggestions.categories.length;
  }
  if (suggestions.brands) {
    count += suggestions.brands.length;
  }
  if (suggestions.products) {
    count += suggestions.products.length;
  }
  if (suggestions.suggestions) {
    count += suggestions.suggestions.length;
  }
  return count;
};

const buildSuggestionHtml = (suggestion) => {
  let suggestionParts = [];
  if (suggestion.image) {
    suggestionParts.push(`<span class="mypet-search__suggestion-image-wrapper"><img src="${suggestion.image}" alt=""></span>`);
  }
  suggestionParts.push(`<span>${suggestion.content}</span>`);
  return `<a href="${suggestion.href}" class="mypet-search__suggestion">${suggestionParts.join("")}</a>`;
};
