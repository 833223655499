export const initSelect = () => {
  document.querySelectorAll(".mypet-select").forEach((selectWrapper) => {
    const select = selectWrapper.querySelector("select");
    if (!select) {
      return;
    }
    if (select.hasAttribute('data-select-init')) {
      return;
    }

    select.setAttribute('data-select-init', 'true');

    const valueElement = selectWrapper.querySelector(".mypet-select__value");
    if (!valueElement) {
      return;
    }

    if (select.selectedIndex !== undefined && select.options !== undefined && select.options[select.selectedIndex] !== undefined) {
      valueElement.innerText = select.options[select.selectedIndex].innerText;
    }

    select.addEventListener('change', (event) => {
      if (select.selectedIndex !== undefined && select.options !== undefined && select.options[select.selectedIndex] !== undefined) {
        const selectedOption = select.options[select.selectedIndex];
        valueElement.innerText = selectedOption.innerText;

        if (selectedOption.hasAttribute('data-url')) {
          window.location = selectedOption.getAttribute('data-url');
        }
      }
    });
  });
};
