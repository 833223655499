import { createForm, FORM_ERROR } from 'final-form';
import "regenerator-runtime/runtime";

/**
 *
 * @param {HTMLElement} formElement
 * @param {object} options
 * @param {object} options.initialValues
 * @param {function} options.onSubmit
 * @param {function} options.validate
 * @param {string} options.successMessage
 */
export const initForm = (formElement, options = {}) => {
  if (!formElement) {
    return;
  }

  // Если у формы есть атрибут data-form-initialized, значит форма уже инициализирована
  if (formElement.hasAttribute("data-form-initialized")) {
    return;
  }

  // Получаем элемент кнопки
  const submitButton = formElement.querySelector("button[type=submit]");

  // Получаем элементы формы
  const formInputs = formElement.querySelectorAll("input,textarea");

  // Получаем элемент ошибки всей формы
  const wholeFormError = formElement.querySelector(".form-whole-error");

  // Получаем элемент сообщения об успехе
  const wholeFormSuccess = formElement.querySelector(".form-whole-success");

  let initialValues = {};
  if (!options.initialValues) {
    formInputs.forEach((input) => {
      if (!input.name) {
        return;
      }

      if (input.type === 'checkbox') {
        initialValues[input.name] = input.checked;
      } else {
        initialValues[input.name] = typeof input.value === undefined ? '' : input.value;
      }
    });
  } else {
    initialValues = options.initialValues;
  }

  // Создаем экземпляр final-form
  const form = createForm({
    initialValues,
    onSubmit: options.onSubmit,
    validate: options.validate,
  });

  // Отмечаем, что мы проиницилизировали эту форму
  formElement.setAttribute("data-form-initialized", "true");

  // Вешаем обработчик отправки формы
  formElement.addEventListener('submit', async (event) => {
    event.preventDefault();
    await form.submit();
  });

  // Регистрируем поля формы
  formInputs.forEach((input) => {
    if (!input.name) {
      return;
    }
    const {name} = input;

    form.registerField(name, (fieldState) => {
      const {
        blur,
        change,
        error,
        submitError,
        focus,
        touched,
        value
      } = fieldState;

      const errorElement = formElement.querySelector(`.form-error--${name}`);
      input.addEventListener('blur', () => blur());
      input.addEventListener('input', event =>
        change(input.type === 'checkbox' ? event.target.checked : event.target.value)
      );
      input.addEventListener('focus', () => focus());

      // update value
      if (input.type === 'checkbox') {
        input.checked = value
      } else {
        input.value = value === undefined ? '' : value
      }

      // show/hide errors
      if (errorElement) {
        if (touched && (error || submitError)) {
          errorElement.innerHTML = error ? error : submitError;
          errorElement.style.display = 'block';
          input.classList.add("mypet-input--error");
        } else {
          errorElement.innerHTML = '';
          errorElement.style.display = 'none';
          input.classList.remove("mypet-input--error");
        }
      }
    }, {
      value: true,
      error: true,
      touched: true,
      submitError: true
    })
  });

  let previousFormState;

  // Подписываемся на события формы
  form.subscribe(
    (formState) => {
      // Update UI

      if (wholeFormError) {
        if (!previousFormState || previousFormState.submitErrors !== formState.submitErrors) {
          if (formState.submitErrors && formState.submitErrors.FORM_ERROR) {
            wholeFormError.innerHTML = formState.submitErrors.FORM_ERROR;
            wholeFormError.style.display = "block";
          } else {
            wholeFormError.style.display = "none";
          }
        }
      }

      if (!previousFormState || previousFormState.submitting !== formState.submitting) {
        if (formState.submitting) {
          if (submitButton) {
            submitButton.classList.add("mypet-button--orange--loading");
            submitButton.setAttribute("disabled", "disabled");
          }
          if (wholeFormError) {
            wholeFormError.style.display = "none";
          }
          if (wholeFormSuccess) {
            wholeFormSuccess.style.display = "none";
          }
        } else {
          if (submitButton) {
            submitButton.classList.remove("mypet-button--orange--loading");
            submitButton.removeAttribute("disabled");
          }
        }
      }

      if (!previousFormState || previousFormState.submitSucceeded !== formState.submitSucceeded) {
        if (formState.submitSucceeded && formState.submitting === false) {
          if (wholeFormSuccess && options.successMessage) {
            wholeFormSuccess.innerHTML = options.successMessage;
            wholeFormSuccess.style.display = "block";
          }
        }
      }

      previousFormState = formState;
    },
    {
      submitting: true,
      submitErrors: true,
      submitSucceeded: true,
      hasSubmitErrors: true,
    }
  );

  return form;
};

export const handleFormErrorResponse = (error, resolve) => {
  const errorsObject = {};
  const errorBody = JSON.parse(error.body);

  if (error.statusCode === 422) {
    Object.keys(errorBody.errors).forEach((fieldKey) => {
      errorsObject[fieldKey] = errorBody.errors[fieldKey][0];
    });

    return resolve(errorsObject);
  }

  resolve({
    FORM_ERROR: errorBody.message ? errorBody.message : "Произошла неизвестная ошибка",
  });
};
