/**
 * Аналог $.ready, выполняет функцию, когда DOM будет готов.
 *
 * @param {function} fn
 */
export const pageReady = (fn) => {
  if (document.readyState !== "loading") {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
};

export const elementByQuery = (query) => {
  return document.querySelector(query);
};

export const sleep = (milliseconds) => {
  return new Promise(res => setTimeout(res, milliseconds));
};

export const fetchScript = (url) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.onload = resolve;
    script.onerror = reject;
    script.src = url;
    script.async = true;

    document.head.appendChild(script);
  });
};
