/**
 * @param {Element} wrapperElement
 * @param {object} options
 * @param {function} options.onChange
 */
export const initQtyControl = (wrapperElement, options = {}) => {
  const setQty = (qty, qtyInput, silent = false) => {
    qtyInput.value = qty;

    if (!silent && typeof options.onChange === "function") {
      options.onChange(qty, qtyInput);
    }

    return qty;
  };

  const qtyButtons = wrapperElement.querySelectorAll(".mypet-input-numeric__qty-button");
  if (qtyButtons.length !== 2) {
    return;
  }

  const qtyInput = wrapperElement.querySelector("input[type=number]");
  if (!qtyInput) {
    return;
  }

  const minusButton = qtyButtons[0];
  const plusButton = qtyButtons[1];

  let qty = setQty(checkQty(qtyInput.value), qtyInput, true);

  checkButton(qty, minusButton);

  qtyInput.addEventListener("focus", (event) => {
    event.target.select();
  });

  qtyInput.addEventListener("change", (event) => {
    qty = checkQty(event.target.value);
    qty = setQty(qty, qtyInput);

    checkButton(qty, minusButton);
  });

  plusButton.addEventListener("click", () => {
    qty = setQty(qty + 1, qtyInput);

    checkButton(qty, minusButton);
  });

  minusButton.addEventListener("click", () => {
    qty = setQty(qty - 1, qtyInput);

    checkButton(qty, minusButton);
  });
};

const checkQty = (qty) => {
  qty = parseInt(qty);
  if (isNaN(qty) || qty <= 0) {
    return 1;
  }

  if (qty > 1000) {
    return 1000;
  }

  return qty;
};

/**
 *
 * @param {number} qty
 * @param {Element} minusButton
 */
const checkButton = (qty, minusButton) => {
  if (qty <= 1) {
    minusButton.setAttribute("disabled", "disabled");
  } else {
    minusButton.removeAttribute("disabled");
  }
};
