import debounce from "lodash/debounce";
import {Xhttp, abortSilently} from 'xhttp'
import "./autocomplete.css";

/**
 * @param {HTMLElement} inputElement
 * @param {object} options
 * @param {string} options.url
 * @param {string} options.queryParam
 * @param {number} options.debounceMs
 * @param {function} options.suggestionRender
 */
export const initAutocomplete = (inputElement, options = {}) => {
  inputElement.setAttribute("autocomplete", "off");
  const suggestionsElement = document.createElement("div");
  suggestionsElement.classList.add("mypet-autocomplete__suggestions");

  const suggestionsContainer = inputElement.parentElement;

  options = Object.assign({
    queryParam: "query",
    debounceMs: 100,
  }, options);

  let suggestions = [];
  let suggestionIndex = -1;
  let inputQuery = "";
  let mousedownHappened = false;

  let autocompleteRequest;

  suggestionsContainer.appendChild(suggestionsElement);

  /**
   * Для предотвращения потери фокуса с поля ввода при клике на подсказе
   */
  suggestionsElement.addEventListener("mousedown", () => {
    mousedownHappened = true;
  });

  inputElement.addEventListener("blur", (event) => {
    if (mousedownHappened) {
      event.preventDefault();
      mousedownHappened = false;

      return;
    }

    suggestionsElement.style.display = "none";
  });

  inputElement.addEventListener("focus", () => {
    if (suggestions.length > 0) {
      suggestionsElement.style.display = "block";
    }
  });

  /**
   * Обработчик ввода запроса
   */
  inputElement.addEventListener("keyup", debounce(async (event) => {
    inputQuery = event.target.value.toString().trim();

    // Скрываем подсказки если поле пустое
    if (inputQuery.length === 0) {
      suggestionsElement.style.display = "none";
      return;
    }

    // Отменяем прошлый запрос
    if (autocompleteRequest) {
      abortSilently(autocompleteRequest);
    }

    try {
      autocompleteRequest = Xhttp({
        url: `${options.url}?${options.queryParam}=${encodeURIComponent(inputQuery)}`,
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        }
      },({ok, status, reason, headers, body}) => {
        if (ok) {
          suggestions = body.suggestions;
          suggestionsElement.style.display = "block";
          if (suggestions.length > 0) {
            suggestionsElement.innerHTML = suggestions.map(suggestion => options.suggestionRender(suggestion)).join("");
          } else {
            suggestionsElement.innerHTML = `<div class="mypet-autocomplete__suggestion mypet-autocomplete__suggestion--empty">Ничего не найдено</div>`;
          }
        }
      });
    } catch (error) {
      //
    }
  }, options.debounceMs));
};
