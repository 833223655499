import "regenerator-runtime/runtime";
import {fetchScript, pageReady} from "./modules/utils";
import {showModal} from "./modules/modals";
import {initAutocomplete} from "./modules/autocomplete/autocomplete";
import request from 'then-request';
import {handleFormErrorResponse, initForm} from "./modules/forms/forms";
import {httpOptions} from "./http";
import {Xhttp} from "xhttp";
import {initSearchAutocomplete} from "./modules/search/search";
import tippy from 'tippy.js';
import nanoid from "nanoid";
import nanoidGenerate from "nanoid/generate";
import {initPickupPointsMap} from "./modules/delivery-map";
import {initQtyControl} from "./modules/qty-control";
import debounce from "lodash/debounce";
import {initSelect} from "./modules/select";

class CommonModule {
  static init = () => {
    if (MpState.commonInit === true) {
      return;
    }
    CommonModule.initCitySelect();
    CommonModule.initGeolocation();
    CommonModule.initOrderInfo();
    CommonModule.initOrderPayModal();
    CommonModule.initLoginModal();
    CommonModule.initRegisterModal();
    CommonModule.initSearchSuggestions();
    CommonModule.initDropdownMenu();
    CommonModule.initMobileDropdownMenu();
    CommonModule.initTooltips();
    CommonModule.initNewsletter();
    CommonModule.initChatra();
    CommonModule.checkIe();
    CommonModule.linksBlur();
    CommonModule.initRegisterPage();

    initSelect();

    MpState.commonInit = true;
  };

  static confirmOrderChatra() {
    if (typeof Chatra === "undefined") {
      showErrorMessage("Онлайн консультант еще не загрузился");
      return;
    }

    CommonModule.reachYmGoal("ORDER_CONFIRM_CHATRA");

    Chatra('openChat', true);
  }

  static initGeolocation() {
    const geoLocationWrapper = document.querySelector("#mypet-sidebar--city-geolocation");
    if (navigator.geolocation) {
      geoLocationWrapper.style.display = "flex";

      const geoButton = document.querySelector("#mypet-sidebar--city-geolocation-button");
      const geoResultsWrapper = document.querySelector("#mypet-sidebar--city-geolocation-results");
      const geoCitiesManualWrapper = document.querySelector("#mypet-sidebar--city-manual-wrapper");
      const geoResultTextElement = document.querySelector("#mypet-sidebar--city-geolocation-result-string");
      const geoLoadingElement = document.querySelector("#mypet-sidebar--city-geolocation-loading");
      const getResultsCities = document.querySelector("#mypet-sidebar--city-geolocation-result-cities");

      geoButton.addEventListener("click", () => {
        CommonModule.reachYmGoal('CITY_GEOLOCATION');

        geoCitiesManualWrapper.style.display = "none";
        geoResultsWrapper.style.display = "block";
        getResultsCities.innerHTML = "";
        geoLoadingElement.style.display = "flex";

        try {
          navigator.geolocation.getCurrentPosition((position) => {
            if (typeof position !== "undefined" && typeof position.coords !== "undefined" && typeof position.coords.longitude !== "undefined" && typeof position.coords.latitude !== "undefined") {
              Xhttp({
                method: "get",
                url: `/cities/closest?lat=${position.coords.latitude}&lon=${position.coords.longitude}`,
                headers: {
                  "Accept": "application/json",
                  "Content-Type": "application/json",
                }
              }, ({ok, status, reason, headers, body}) => {
                geoLoadingElement.style.display = "none";

                if (ok && body.status === true) {
                  geoResultTextElement.innerText = "Мы нашли несколько населенных пунктов недалеко от вас, пожалуйста, выберите нужный:";

                  body.cities.forEach((cityInfo) => {
                    const cityElement = document.createElement("a");
                    cityElement.classList.add("mypet-sidebar__city-block");
                    cityElement.setAttribute("href", `/cities/select/${cityInfo.url}`);
                    cityElement.innerText = cityInfo.name;

                    getResultsCities.appendChild(cityElement);
                  })
                } else {
                  geoResultTextElement.innerText = "Произошла ошибка получения геолокации";
                }

                geoResultTextElement.style.display = "block";
              });
            } else {
              geoResultTextElement.innerText = "Произошла ошибка получения геолокации, проверьте настройки браузера или доступ нашего сайта в настройках";
              geoResultTextElement.style.display = "block";
              geoLoadingElement.style.display = "none";
            }
          }, (err) => {
            if (err.code === 1) {
              geoResultTextElement.innerText = "Пожалуйста, разрешите доступ к геолокации, чтобы найти ваш населенный пункт";
            } else if (err.code === 2) {
              geoResultTextElement.innerText = "При определении положения произошла ошибка";
            } else if (err.code === 3) {
              geoResultTextElement.innerText = "Не получилось определить геолокацию в отведенное время";
            } else {
              geoResultTextElement.innerText = "Не удалось получить геолокацию";
            }

            geoResultTextElement.style.display = "block";
            geoLoadingElement.style.display = "none";
          });
        } catch (e) {
          geoResultTextElement.innerText = "Произошла ошибка получения геолокации";
          geoResultTextElement.style.display = "block";
          geoLoadingElement.style.display = "none";
        }
      });
    }
  }

  /**
   * Вешает обработчики событий для смены города клиента.
   */
  static initCitySelect() {
    document.querySelectorAll(".mypet-select-city").forEach((element) => {
      element.addEventListener("click", (event) => {
        event.preventDefault();

        // Если размеры экрана менее 750, показываем мобильный выбор города
        if (document.documentElement.clientWidth < 750) {
          // Показываем секцию выбора города
          CommonModule.showSideBarSection("city-select");
          MypetOnline.layout.default.openHamburgerMenu();
        } else {
          showModal({
            id: "city-select",
            ajax: true,
            url: "/modals/city-select",
          })
            .then(() => {
              const citySearchInput = document.getElementById('city-select__search-input');
              citySearchInput.focus();

              initAutocomplete(citySearchInput, {
                url: "/delivery/city/autocomplete",
                queryParam: "term",
                suggestionRender: (suggestion) => {
                  const regionParts = [];
                  if (suggestion.area) {
                    regionParts.push(suggestion.area);
                  }
                  if (suggestion.region) {
                    regionParts.push(suggestion.region);
                  }

                  const regionInfo = regionParts.join(", ");
                  return `<a class="mypet-autocomplete__suggestion" href="/cities/select/${suggestion.url}"><span class="city-select__suggestion-title">${suggestion.name}</span><span class="city-select__suggestion-subtitle">${regionInfo}</span></a>`;
                }
              });
            })
        }
      });
    });

    document.querySelectorAll(".mypet-sidebar__item--show-main").forEach((element) => {
      element.addEventListener("click", (event) => {
        event.preventDefault();

        // Показываем главную секцию
        CommonModule.showSideBarSection("main");
      });
    });
  }

  /**
   * Вешает обработчик на модалку оформления заказа
   */
  static initOrderInfo() {
    document.querySelectorAll(".mypet-modal__order-status").forEach((element) => {
      element.addEventListener("click", (event) => {
        event.preventDefault();
        showModal({
          id: "order-info",
          ajax: true,
          url: "/modals/order/info",
          onInit: () => {
            const formElement = document.getElementById("modal-order-info__form");

            initForm(formElement, {
              onSubmit: async (values, form) => {
                return new Promise((resolve) => {
                  request('POST', '/order/access', {json: values})
                    .getBody('utf8')
                    .then(JSON.parse)
                    .catch((error) => {
                      handleFormErrorResponse(error, resolve);
                      throw new Error;
                    })
                    .done((response) => {
                      if (response.status === true && response.redirect_url) {
                        window.location.href = response.redirect_url;
                      }
                      resolve();
                    });
                });
              },
              validate: (values) => {
                const errors = {};
                if (!values.order_id) {
                  errors.order_id = 'Введите номер заказа'
                }
                if (!values.phone) {
                  errors.phone = 'Введите номер телефона, на который оформлен заказ'
                }
                return errors;
              },
              successMessage: "Все правильно, сейчас вы будете перенаправлены на страницу с информацией о заказе...",
            });
          }
        })
          .then(() => {
            //
          })
      })
    });
  }

  static initOrderPayModal() {
    document.querySelectorAll(".mypet-modal__order-pay").forEach((element) => {
      element.addEventListener("click", (event) => {
        event.preventDefault();
        showModal({
          id: "order-pay",
          ajax: true,
          url: "/modals/order/pay",
          onInit: () => {
            const formElement = document.getElementById("modal-order-pay__form");

            initForm(formElement, {
              onSubmit: async (values, form) => {
                console.log("onSubmit");
                return new Promise((resolve) => {
                  request('POST', '/order/pay-access', {json: values})
                    .getBody('utf8')
                    .then(JSON.parse)
                    .catch((error) => {
                      handleFormErrorResponse(error, resolve);
                      throw new Error;
                    })
                    .done((response) => {
                      if (response.status === true && response.redirect_url) {
                        window.location.href = response.redirect_url;
                      }
                      resolve();
                    });
                });
              },
              validate: (values) => {
                const errors = {};
                if (!values.order_id) {
                  errors.order_id = 'Введите номер заказа'
                }
                return errors;
              },
              successMessage: "Секунду, сейчас вы будете перенаправлены на страницу для оплаты заказа...",
            });
          }
        })
          .then(() => {
            //
          })
      })
    });
  }

  static initLoginModal() {
    document.querySelectorAll(".mypet-modal__login").forEach((element) => {
      element.addEventListener("click", (event) => {
        event.preventDefault();
        showModal({
          id: "login",
          ajax: true,
          url: "/modals/auth/login",
          onInit: () => {
            const formElement = document.getElementById("modal-login__form");

            initForm(formElement, {
              onSubmit: async (values, form) => {
                return new Promise((resolve) => {
                  request('POST', '/login/attempt', httpOptions({json: values}))
                    .getBody('utf8')
                    .then(JSON.parse)
                    .catch((error) => {
                      handleFormErrorResponse(error, resolve);
                      throw new Error;
                    })
                    .done((response) => {
                      if (response.status === true) {
                        window.location.reload();
                      }
                      resolve();
                    });
                });
              },
              validate: (values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = 'Введите email или телефон'
                }
                if (!values.password) {
                  errors.password = 'Введите пароль'
                }
                return errors;
              },
              successMessage: "Вы успешно вошли, страница обновляется...",
            });
          }
        })
          .then(() => {
            //
          })
      })
    });
  }

  static confirmLogout(logoutUrl) {
    showConfirm({
      title: "Выход",
      text: "Вы действительно хотите выйти?",
      confirmButtonText: "Выйти",
      onConfirm: () => {
        location.href = logoutUrl;
      }
    });
  }

  static initRegisterModal() {
    document.querySelectorAll(".mypet-modal__register").forEach((element) => {
      element.addEventListener("click", (event) => {
        event.preventDefault();

        CommonModule.loadReCaptcha();

        showModal({
          id: "register",
          ajax: true,
          url: "/modals/auth/register",
          onInit: () => {
            const formElement = document.getElementById("modal-register__form");

            initForm(formElement, {
              onSubmit: async (values, form) => {
                return new Promise((resolve) => {
                  grecaptcha.ready(function () {
                    grecaptcha.execute(window.MpState.settings.recaptcha_site_key, {action: 'signup_submit'}).then((token) => {
                      const formValues = Object.assign({}, values, {re_token: token});
                      request('POST', '/register', httpOptions({json: formValues}))
                        .getBody('utf8')
                        .then(JSON.parse)
                        .catch((error) => {
                          handleFormErrorResponse(error, resolve);
                        })
                        .done((response) => {
                          if (response.status === true) {
                            window.location.reload();
                          }
                          resolve();
                        });
                    })
                  });
                });
              },
              initialValues: {
                accept_news: false,
              },
              validate: (values) => {
                const errors = {};
                if (!values.name) {
                  errors.name = 'Укажите ваше имя';
                }
                if (!values.email) {
                  errors.email = 'Введите email';
                }
                if (!values.phone) {
                  errors.phone = 'Введите ваш телефон';
                }
                if (!values.pass) {
                  errors.pass = 'Введите пароль';
                } else if (values.pass.toString().length < 6) {
                  errors.pass = 'Минимальная длина пароля - 6 символов';
                }

                return errors;
              },
              successMessage: "Вы успешно зарегистрировались, страница обновляется...",
            });
          }
        })
          .then(() => {
            //
          })
      })
    });
  }

  static initRegisterPage() {
    if (window.MpState.route.name !== 'auth:register') {
      return;
    }
    pageReady(() => {
      CommonModule.loadReCaptcha();

      const formElement = document.getElementById("page-register__form");

      initForm(formElement, {
        onSubmit: async (values) => {
          return new Promise((resolve) => {
            grecaptcha.ready(function () {
              grecaptcha.execute(window.MpState.settings.recaptcha_site_key, {action: 'signup_submit'}).then((token) => {
                const formValues = Object.assign({}, values, {re_token: token});
                request('POST', '/register', httpOptions({json: formValues}))
                  .getBody('utf8')
                  .then(JSON.parse)
                  .catch((error) => {
                    handleFormErrorResponse(error, resolve);
                  })
                  .done((response) => {
                    if (response.status === true) {
                      window.location.reload();
                    }
                    resolve();
                  });
              })
            });
          });
        },
        initialValues: {
          accept_news: false,
        },
        validate: (values) => {
          const errors = {};
          if (!values.name) {
            errors.name = 'Укажите ваше имя';
          }
          if (!values.email) {
            errors.email = 'Введите email';
          }
          if (!values.phone) {
            errors.phone = 'Введите ваш телефон';
          }
          if (!values.pass) {
            errors.pass = 'Введите пароль';
          } else if (values.pass.toString().length < 6) {
            errors.pass = 'Минимальная длина пароля - 6 символов';
          }

          return errors;
        },
        successMessage: "Вы успешно зарегистрировались, страница обновляется...",
      });
    });
  }

  /**
   * Показывает выбранную секцию сайдбара мобильных устройств
   *
   * @param section
   */
  static showSideBarSection(section) {
    // Скрываем все секции сайдбара в мобильной версии
    document.querySelectorAll(".mypet-sidebar__section").forEach((sideBarSection) => {
      sideBarSection.classList.remove("mypet-sidebar__section--visible");
    });

    // Показываем раздел с выбором города
    document.getElementById(`mypet-sidebar--${section}`).classList.add("mypet-sidebar__section--visible");
  }

  static showResendConfirmationModal() {
    showModal({
      id: "register",
      ajax: true,
      url: "/modals/confirmation/resend-modal",
      onInit: () => {
        const submitButton = document.querySelector("#mypet-modal__resend-confirmation-button");
        if (submitButton) {
          submitButton.addEventListener("click", () => {
            const errorElement = document.querySelector("#mypet-modal__resend-confirmation-error");
            errorElement.style.display = "none";

            submitButton.classList.add("mypet-button--orange--loading");
            submitButton.setAttribute("disabled", "disabled");

            Xhttp({
              method: "POST",
              url: "/account/confirmation/resend",
              headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
              }
            }, ({ok, status, reason, headers, body}) => {
              if (ok && body.status === true) {
                document.querySelector("#mypet-modal__resend-confirmation-form").style.display = "none";
                document.querySelector("#mypet-modal__resend-confirmation-result").style.display = "block";
              } else {
                errorElement.innerHTML = body && body.message ? body.message : "Произошла ошибка, попробуйте еще раз";
                errorElement.style.display = "block";
              }
            });
          });
        }
      }
    })
      .then(() => {
        //
      })
  }

  static initSearchSuggestions() {
    document.querySelectorAll(".mypet-search-autocomplete").forEach((element) => {
      initSearchAutocomplete(element);
    })
  }

  static initDropdownMenu() {
    window.addEventListener("click", () => {
      document.querySelectorAll(".mypet-top-nav__dropdown-menu-list").forEach((element) => {
        element.classList.remove("mypet-common--visible");
      });
    });

    document.querySelectorAll(".mypet-top-nav__dropdown-menu").forEach((dropdownWrapper) => {
      const dropdownLink = dropdownWrapper.querySelector(".mypet-top-nav__item");
      const dropdownMenu = dropdownWrapper.querySelector(".mypet-top-nav__dropdown-menu-list");

      dropdownLink.addEventListener("click", (event) => {
        event.preventDefault();
        event.stopPropagation();
        dropdownMenu.classList.toggle("mypet-common--visible");
      });
    });
  }

  static initMobileDropdownMenu() {
    document.querySelectorAll(".mypet-mobile__sidebar-menu").forEach((wrapperElement) => {
      const dropdownToggle = wrapperElement.querySelector(".mypet-mobile__sidebar-menu-toggle");

      dropdownToggle.addEventListener("click", (event) => {
        event.preventDefault();
        wrapperElement.classList.toggle("mypet-mobile__sidebar-menu--visible");
      });
    });
  }

  static initTooltips() {
    tippy('.mypet-info-tooltip', {
      arrow: true,
      theme: "light"
    });

    tippy('.mypet-tooltip', {
      arrow: true,
    });
  }

  static initNewsletter() {
    document.querySelector("#mypet-newsletter-subscribe__subscribe-button").addEventListener("click", () => {
      document.querySelector("#mypet-newsletter-subscribe__teaser-layout").style.display = "none";
      document.querySelector("#mypet-newsletter-subscribe__subscribe-layout").classList.remove("mypet-common--hidden");
      CommonModule.loadReCaptcha();
    });

    const subscribeForm = document.querySelector("#mypet-newsletter-subscribe__subscribe-form");
    subscribeForm.addEventListener("submit", (event) => {
      event.preventDefault();

      const emailInput = subscribeForm.querySelector("input[type=email]");
      const submitButton = subscribeForm.querySelector("button[type=submit]");

      submitButton.classList.add("mypet-button--orange--loading");
      submitButton.setAttribute("disabled", "disabled");

      grecaptcha.ready(function () {
        grecaptcha.execute(window.MpState.settings.recaptcha_site_key, {action: 'newsletter_submit'}).then((token) => {
          Xhttp({
            method: "POST",
            url: "/marketing/newsletter/subscribe",
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
            },
            body: {
              email: emailInput.value,
              re_token: token,
            }
          }, ({ok, status, reason, headers, body}) => {
            submitButton.classList.remove("mypet-button--orange--loading");
            submitButton.removeAttribute("disabled");

            if (ok && body.status === true) {
              document.querySelector("#mypet-newsletter-subscribe__subscribe-steps-layout").style.display = "none";
              document.querySelector("#mypet-newsletter-subscribe__subscribed-layout").classList.remove("mypet-common--hidden");
            } else {
              showErrorMessage(body && body.message ? body.message : "Произошла ошибка, попробуйте еще раз");
            }
          });
        });
      });
    });
  }

  static initChatra() {
    window.ChatraSetup = {
      colors: [
        '#ffffff', // цвет текста
        '#E77D00'  // цвет фона
      ]
    };
    window.ChatraID = 'pNzXk8AGauEnXFK4y';

    window.addEventListener('load', () => {
      (function (d, w, c) {
        let n = d.getElementsByTagName('script')[0],
          s = d.createElement('script');
        w[c] = w[c] || function () {
          (w[c].q = w[c].q || []).push(arguments);
        };
        s.defer = true;
        s.src = (d.location.protocol === 'https:' ? 'https:' : 'http:')
          + '//chat.chatra.io/chatra.js';
        n.parentNode.insertBefore(s, n);
      })(document, window, 'Chatra');
    });
  }

  static addToCart(productId, qty = 1, params = {}) {
    const bigButtonsWrappers = document.querySelectorAll(`.mypet-cart-button--big--${productId}`);
    const listedButtonsWrapper = document.querySelectorAll(`.mypet-cart-button-listed--${productId}`);

    bigButtonsWrappers.forEach((element) => {
      const buttonElement = element.querySelector("button");
      if (buttonElement) {
        buttonElement.setAttribute("disabled", "disabled");
        buttonElement.classList.add("mypet-button--orange--loading");
      }
    });

    listedButtonsWrapper.forEach((element) => {
      const buttonElement = element.querySelector("button");
      if (buttonElement) {
        buttonElement.setAttribute("disabled", "disabled");
        buttonElement.classList.remove("mypet-button--orange-light");
        buttonElement.classList.add("mypet-button--orange");
        buttonElement.classList.add("mypet-button--orange--loading");
      }
    });

    const body = Object.assign({}, {
      product_id: productId,
      qty: qty,
    }, params);
    Xhttp({
      method: "post",
      url: "/cart/add",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      body,
    }, ({ok, body}) => {
      if (ok) {
        document.querySelector("#mypet-header-cart-wrapper").innerHTML = body.cart_header;
        document.querySelector("#mypet-cart-header-mobile-wrapper").innerHTML = body.cart_header_mobile;
        bigButtonsWrappers.forEach((element) => {
          element.innerHTML = body.button_big;
        });

        listedButtonsWrapper.forEach((element) => {
          element.innerHTML = body.button_listed;
        });

        const qtyButtonsWrapper = document.querySelector(".mypet-product-page__qty-button-wrapper");
        if (qtyButtonsWrapper) {
          qtyButtonsWrapper.style.display = "none";
        }

        if (window.MpGtmProductData && window.MpGtmProductData[productId]) {
          const gtmList = params && params.gtm_list ? params.gtm_list : '';
          dataLayer.push({
            'event': 'addToCart',
            'ecommerce': {
              'currencyCode': 'RUB',
              'add': {
                'products': [Object.assign(window.MpGtmProductData[productId], {quantity : qty, list: gtmList})]
              }
            }
          });
        }

        if (body.product) {
          CommonModule.reachYmGoal('CART_INSERT');
        }

        const modalId = `cart-product-added-${nanoid()}`;
        showModal({
          id: modalId,
          ajax: true,
          url: `/modals/cart/product-added?product_id=${productId}`,
        })
          .then(() => {
            const modalWrapper = document.querySelector(`#mypet-modal-wrapper--${modalId}`);
            if (modalWrapper) {
              const modalQtyInput = modalWrapper.querySelector(".mypet-input-numeric__wrapper");
              if (modalQtyInput) {
                initCartQtyInput(modalQtyInput);
              }
            }
          });
      }
    })
  }

  static gtmProductClick(list, position = 1, productId) {
    if (list && window.MpGtmProductData && window.MpGtmProductData[productId]) {
      window.dataLayer.push({
        'event': 'productClick',
        'ecommerce': {
          'click': {
            'actionField': {'list': list},          // Наследуемое изменяемое поле - значения выше
            'products': [Object.assign(window.MpGtmProductData[productId], {position})]
          }
        }
      });
    }
  }

  static showQuickOrderModal(type, productId = null) {
    const modalElementId = `quick-order-${type}-${productId ? productId : 'none'}-${nanoid()}`;
    const modalWrapperElementId = `mypet-modal-wrapper--${modalElementId}`;

    showModal({
      id: modalElementId,
      ajax: true,
      url: `/modals/quick-order?type=${type}&product_id=${productId ? productId : ''}`,
    })
      .then(() => {
        const formElement = document.querySelector(`#${modalWrapperElementId}`).querySelector("form");

        initForm(formElement, {
          onSubmit: async (values) => {
            return new Promise((resolve) => {
              request('POST', '/order/create/quick', {
                json: {
                  name: values.name,
                  phone: values.phone,
                  type,
                  product: productId,
                }
              })
                .getBody('utf8')
                .then(JSON.parse)
                .catch((error) => {
                  handleFormErrorResponse(error, resolve);
                  throw new Error;
                })
                .done((response) => {
                  if (response.status === true && response.redirect_url) {
                    window.location.href = response.redirect_url;
                  }
                  resolve();
                });
            });
          },
          validate: (values) => {
            const errors = {};
            if (!values.phone) {
              errors.phone = 'Введите номер телефона для связи с вами'
            }
            if (!values.name) {
              errors.name = 'Введите ваше имя'
            }
            return errors;
          },
          successMessage: "Заказ оформлен, перенаправляем...",
        });
      });
  }

  static showDeliveryPickupPoints(cityUrl, companyUrl = null) {
    const modalId = `pickup-points--${cityUrl}-${companyUrl ? companyUrl : 'all'}`;
    const modalUniqueId = nanoidGenerate("abcdefghijklmnopqrstuvwzyz", 10);

    showModal({
      id: modalId,
      ajax: true,
      mobileFixed: true,
      url: `/modals/delivery/pickup-points?city_url=${cityUrl}&company=${companyUrl ? companyUrl : ''}&modal_id=${modalUniqueId}`,
      onInit: () => {
        initPickupPointsMap(modalId, modalUniqueId);
      }
    })
      .then(() => {
        const wrapperElement = document.querySelector(`#mypet-modal-wrapper--${modalId}`);
        wrapperElement.querySelector(".mypet-modal-pickup-points__container").style.height = `${document.documentElement.clientHeight - 120}px`;
      });
  }

  static yandexMapSetCenter(mapId, lat, lon) {
    if (typeof MpState.custom.yandexMaps === "undefined" || typeof MpState.custom.yandexMaps[mapId] === "undefined") {
      return;
    }

    MpState.custom.yandexMaps[mapId].setCenter([lat, lon], 16);
  }

  static reachYmGoal(goalId) {
    if (typeof ym !== "undefined") {
      ym(9956113, 'reachGoal', goalId);
    } else {
      document.addEventListener("yacounter9956113inited", function () {
        if (typeof ym !== "undefined") {
          ym(9956113, 'reachGoal', goalId);
        }
      });
    }
  }

  static checkIe() {
    let isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    if (isIE11) {
      document.querySelector("body").classList.add("mypet--ie-11");
    }
  }

  static linksBlur() {
    document.querySelectorAll('a').forEach(element => {
      element.addEventListener('click', (event) => {
        if (event.target && typeof event.target.blur !== "undefined") {
          event.target.blur();
        }
      })
    })
  }

  static loadReCaptcha() {
    if (window.__MYPET_RECAPTCHA_LOADED === true) {
      return;
    }

    fetchScript(`https://www.google.com/recaptcha/api.js?render=${window.MpState.settings.recaptcha_site_key}`)
      .then(() => {
        window.__MYPET_RECAPTCHA_LOADED = true;
      });
  }
}

pageReady(() => {
  CommonModule.init();
});

export const showConfirm = (options = {}) => {
  const confirmWrapper = document.querySelector("#mypet-modal-wrapper--confirm");
  const confirmHeader = confirmWrapper.querySelector("div.h1");
  const confirmText = confirmWrapper.querySelector("p.mypet-confirm__text");
  const confirmButton = confirmWrapper.querySelector("button.mypet-confirm__confirm-button");

  options = Object.assign({
    title: "Внимание",
    text: "",
    confirmButtonText: "Да",
  }, options);

  confirmHeader.innerText = options.title;
  confirmText.innerText = options.text;
  confirmButton.innerText = options.confirmButtonText;

  if (typeof options.onConfirm === "function") {
    MpState.confirmHandler = () => {
      confirmWrapper.classList.remove("mypet-modal-wrapper--visible");
      options.onConfirm();
    };
  }

  showModal({
    id: "confirm",
    isConfirm: true,
  })
    .then(() => {
      confirmButton.focus();
    })
};

export const showErrorMessage = (message) => {
  alert(message);
};

export const initCartQtyInput = (element) => {
  if (element.getAttribute("data-qty-enabled") === "true") {
    return;
  }
  element.setAttribute("data-qty-enabled", "true");

  initQtyControl(element, {
    onChange: debounce((newQty, inputElement) => {
      const productId = inputElement.getAttribute("data-product-id");
      const productRow = document.querySelector(`#mypet-products-table__cell--${productId}`);
      if (productRow) {
        productRow.style.opacity = 0.5;
      }

      Xhttp({
        method: "post",
        url: "/cart/change_qty",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          product_id: productId,
          qty: newQty,
        }
      }, ({ok, body}) => {
        if (productRow) {
          productRow.style.opacity = 1;
        }
        if (ok) {
          document.querySelector("#mypet-header-cart-wrapper").innerHTML = body.cart_header;
          document.querySelector("#mypet-cart-header-mobile-wrapper").innerHTML = body.cart_header_mobile;
          document.querySelectorAll(".mypet-cart__products-amount").forEach((element) => element.innerHTML = body.cart.amount_fmt);
          document.querySelectorAll(".mypet-cart__products-count").forEach((element) => element.innerHTML = body.cart.products_fmt);
          if (productRow) {
            productRow.querySelector(".products-table__product-amount").innerHTML = body.product.amount_fmt;
          }
          const amountWarning = document.querySelector("#mypet-cart__amount-warning");
          if (amountWarning) {
            if (body.cart.amount >= MpState.settings.orders_min_amount) {
              amountWarning.style.display = "none";
            } else {
              amountWarning.style.display = "block";
            }
          }

          if (body.cart.delivery_price_fmt) {
            const deliveryPriceElement = document.querySelector(".mypet-cart-page__local-delivery-price");
            if (deliveryPriceElement) {
              deliveryPriceElement.innerHTML = body.cart.delivery_price_fmt;
            }
          }
        }
      });
    }, 400),
  });
};

export default CommonModule;
